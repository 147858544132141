// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "bootstrap";
import "../styles/application";
import "../../../node_modules/select2/dist/js/select2.min.js";
import "@client-side-validations/client-side-validations";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
var $ = require("jquery");
require("admin-lte");
require("./nested-forms/addFields");
require("./nested-forms/removeFields");

$(document).on("turbolinks:load", function () {
  $("form").on("click", ".remove_fields", function (e) {
    $(this).prev("input[type=hidden]").val("true");
    $(this).closest(".nested-fields").hide();
    return e.preventDefault();
  });
  $("select").select2({
    theme: "bootstrap-5",
    selectionCssClass: "select2--large", 
  });
  
  let cardToggles = document.getElementsByClassName("card-toggle");
  for (let i = 0; i < cardToggles.length; i++) {
    cardToggles[i].addEventListener("click", (e) => {
      console.log(e.currentTarget, "target");
      console.log(
        e.currentTarget.parentElement.parentElement.childNodes,
        "child nodes"
      );
      e.currentTarget.parentElement.parentElement.childNodes[3].classList.toggle(
        "is-hidden"
      );
    });
  }

  $(".getTreatmentCategoryValue").on("click", function () {
    $(".getTreatmentCategoryValue").removeClass("activeCategory");
    $(this).addClass("activeCategory");
  });
  $(".getTreatmentCategoryConcernValue").on("click", function () {
    $(".getTreatmentCategoryConcernValue").removeClass("activeCategory");
    $(this).addClass("activeCategory");
  });
  $(".modal-close").click(function () {
    $(".modal").removeClass("is-active");
  });
});
window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    console.log('ServiceWorker registered: ', registration);

    var serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
      console.log('Service worker installing.');
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      console.log('Service worker installed & waiting.');
    } else if (registration.active) {
      serviceWorker = registration.active;
      console.log('Service worker active.');
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});